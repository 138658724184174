<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>画板</el-breadcrumb-item>
      <el-breadcrumb-item>画板列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input
            clearable
            placeholder=" 请输入内容"
            v-model="queryinfo.order_no"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchChange()"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-table :data="userlist" border stripe>
        <el-table-column label="画板名称" prop="drawing_board_name"></el-table-column>
        <el-table-column label="采集数量" prop="drawings_count"></el-table-column>
        <el-table-column label="作者名称" prop="author.nickname">
          <!-- <template slot-scope="scope">
            <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="scope.row.use_status"
              active-text="是否开启"
              @change="modifyUseStatus(scope.row.id, scope.row.use_status)"
            >
            </el-switch>
          </template> -->
        </el-table-column>
        <el-table-column label="画板分类" prop="drawing_board_category.category_name"></el-table-column>
        <el-table-column label="上传时间" prop="create_time"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            
            <el-button
              type="primary"
              icon="el-icon-delete"
              size="mini"
              @click="deleteEditDialog(scope.row.drawing_board_id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页区域-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.page"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="queryinfo.list_rows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 修改文章信息 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取文章信息列表
      queryinfo: {
        order_no: "",
        page: 1,
        list_rows: 5,
      },
      userlist: [],
      total: 0,
      //控制创建文章对话框展示与隐藏
      addForm: {
        title: "",
        editor: "",
        body: "",
        // 上传的图片临时路径
        pics: [],
      },
      addRules: {
        title: [
          {
            required: true,
            message: "请输入文章标题",
            trigger: "blur",
          },
        ],
        editor: [
          {
            required: true,
            message: "请输入作者名称",
            trigger: "blur",
          },
        ],
        body: [
          {
            required: true,
            message: "请输入文本内容",
            trigger: "blur",
          },
        ],
      },
      editDialogVisible: false,
      options: [
        {
          value: "2",
          label: "审核通过",
        },
        {
          value: "3",
          label: "驳回",
        },
      ],
      editForm: {},
      editRules: {
        title: [
          {
            required: true,
            message: "请输入文章标题",
            trigger: "blur",
          },
        ],
        editor: [
          {
            required: true,
            message: "请输入作者名称",
            trigger: "blur",
          },
        ],
        source: [
          {
            required: true,
            message: "请输入文章来源",
            trigger: "blur",
          },
        ],
        body: [
          {
            required: true,
            message: "请输入文本内容",
            trigger: "blur",
          },
        ],
      },
      // 上传图片的url
      uploadUrl: "http://127.0.0.1:8887/api/upload",
      // 图片上传组件的headers请求头对象
      headerObj: {
        Authorization: window.sessionStorage.getItem("token"),
      },
      previewPath: "",
      previewVisible: false,
    };
  },
  created() {
    this.getinforList();
  },
  methods: {
    // 获取所有菜单
    async getinforList() {
      const { data: res } = await this.$http.post("drawingBoard/index", {
        // params: this.queryinfo,
        drawing_board_name: this.queryinfo.order_no,
        page: this.queryinfo.page,
        list_rows: this.queryinfo.list_rows,
      });

      if (res.code !== 0) return this.$message.err("获取画板列表失败!");
      this.userlist = res.data.data;
      this.total = res.data.total;
    },
    // 监听handleSizeChange方法
    handleSizeChange(newsize) {
      this.queryinfo.list_rows = newsize;
      this.getinforList();
    },
    // 监听handleCurrentChange方法
    handleCurrentChange(newpage) {
      this.queryinfo.page = newpage;
      this.getinforList();
    },
    // 搜索方法
    searchChange() {
      this.queryinfo.page = 1;
      this.getinforList();
    },
    // 添加用户关闭
    addDialogClosed() {
      this.$refs["addFormRef"].resetFields();
    },
    //删除用户
    async deleteEditDialog(id) {
      // console.log(id);
      const confirmRessult = await this.$confirm(
        "此操作将永久删除, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);

      if (confirmRessult !== "confirm") {
        return this.$message.info("已经取消删除!");
      }
      const { data: res } = await this.$http.post("drawingBoard/delete", {
        drawing_board_id: id,
      });
      if (res.code !== 0) {
        return this.$message.error("删除失败!");
      }
      console.log(res);
      this.$message.success("删除成功!");
      this.getinforList();
    },
    editDialogClosed() {
      this.$refs["editFormRef"].resetFields();
    },
    editUser() {
      // 预验证
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return;
        // 调用后台数据创建用户
        const { data: res } = await this.$http.post(
          "updateWikis/" + this.editForm.id,
          this.editForm
        );
        if (res.code !== 0) return this.$message.error(res.meta.msg);
        this.$message.success("编辑成功");
        //    关闭添加窗口
        this.editDialogVisible = false;
        this.getinforList();
      });
    },
    
    //修改使用状态
    async modifyUseStatus(id, status) {
      const { data: res } = await this.$http.post("drawingBoard/modifyUseStatus", {
        id: id,
        use_status: status,
      });
      this.$message.success(res.message);
      //   this.getinforList();
    },
  },
};
</script>

<style lang="less" scoped>
.el-image {
  width: 100px;
  height: 160px;
  object-fit: cover;
}
</style>