<template>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>文章管理</el-breadcrumb-item>
            <el-breadcrumb-item></el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
          <el-row :gutter="20">
                <el-col :span="7">
                    <el-input clearable placeholder=" 请输入内容" v-model="queryinfo.query" >
                        <el-button slot="append" icon="el-icon-search" @click="getinforList"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="addDialogVisible=true">添加文章</el-button>
                </el-col>
            </el-row>
            <el-table :data="userlist" border stripe>
                <el-table-column label="角色编号" prop="id"></el-table-column>
                <el-table-column label="角色名称" prop="role_name">
                </el-table-column>
                <el-table-column label="菜单id的集合" prop="menu_ids">
                </el-table-column>
                <el-table-column label="状态" prop="use_status_text">
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)"></el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini" @click=deleteEditDialog(scope.row.id)></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--分页区域-->
             <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryinfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryinfo.list_rows" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
        <!--新增文章-->
        <el-dialog title="新增文章" :visible.sync="addDialogVisible" width="50%" @closed="addDialogClosed">
            <el-form :model="addForm" :rules="addRules" ref="addFormRef" label-width="100px">
                <el-form-item label="角色名称" prop="role_name">
                    <el-input v-model="addForm.role_name"></el-input>
                </el-form-item>
                <el-form-item label="菜单id的集合" prop="menu_ids">
                    <el-input v-model="addForm.menu_ids"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addUser">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 修改文章信息 -->
        <el-dialog title="修改文章信息" :visible.sync="editDialogVisible" width="50%" @closed="editDialogClosed">
            <el-form :model="editForm" :rules="editRules" ref="editFormRef" label-width="70px">
                <el-form-item label="角色名称" prop="role_name">
                    <el-input v-model="editForm.role_name" ></el-input>
                </el-form-item>
                <el-form-item label="菜单id的集合" prop="menu_ids">
                    <el-input v-model="editForm.menu_ids"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editUser">确 定</el-button>
            </span>
        </el-dialog>
        </div> 
</template>

<script>
    export default{
        data(){
            return{
                // 获取文章信息列表
                queryinfo:{
                    query:'',
                    page:1,
                    list_rows:5,
                },
                userlist:[],
                total:0,
                //控制创建文章对话框展示与隐藏
                addDialogVisible:false,
                addForm:{
                    role_name:'',
                    menu_ids:'',
                },
                addRules: {
                    role_name: [{
                        required: true,
                        message: '请输入角色名称',
                        trigger: 'blur'
                    }],
                    menu_ids: [{
                        required: true,
                        message: '请输入菜单id集合',
                        trigger: 'blur'
                    }],

                },
                editDialogVisible:false,
                 editForm:{},
                 
                 editRules:{
                    role_name: [{
                        required: true,
                        message: '请输入角色名称',
                        trigger: 'blur'
                    }],
                    menu_ids: [{
                        required: true,
                        message: '请输入菜单id集合',
                        trigger: 'blur'
                    }],
                },
                ids:{
id:0,
                },
                // 图片上传组件的headers请求头对象
                headerObj: {
                    Authorization: window.sessionStorage.getItem('token')
                },
                previewPath: '',
                previewVisible: false
            }
        },
        created(){
            this.getinforList()
        },
        methods: {
            // 获取所有菜单
            async getinforList(){
                const {data:res}=await this.$http.get('adminRole/index',{params:this.queryinfo});

                if(res.code!==0) return this.$message.err('获取拓展信息列表失败!')
                this.userlist=res.data.data;
                this.total=res.data.total;
                console.log(this.userlist);
            },
             // 监听handleSizeChange方法
            handleSizeChange(newsize){
                this.queryinfo.list_rows=newsize
                this.getinforList()
            },
            // 监听handleCurrentChange方法
            handleCurrentChange(newpage){
                this.queryinfo.page=newpage
                this.getinforList()
            },
             // 添加用户关闭
            addDialogClosed(){
                this.$refs['addFormRef'].resetFields();
            },
            addUser(){
                // 预验证
                console.log(1245,this.addForm);
                 this.$refs.addFormRef.validate(async valid=>{
                    if(!valid) return
                    // 调用后台数据创建用户
                   const {data:res}=await this.$http.post('adminRole/add',this.addForm)
                   if(res.code!==0) return this.$message.error(res.message)
                   this.$message.success('添加成功')
                    //    关闭添加窗口
                    this.addDialogVisible=false
                    this.getinforList()
                })
            },
            //删除用户
            async deleteEditDialog(id){
                // console.log(id);
                const confirmRessult = await this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch(err => err)

                if (confirmRessult !== 'confirm') {
                    return this.$message.info('已经取消删除!')
                }
                const {
                    data: res
                } = await this.$http.delete(`wikipedia/${id}`)
                if (res.meta.status !== 200) {
                    return this.$message.error('删除失败!')
                }
                console.log(res);
                this.$message.success('删除成功!')
                this.getinforList()
            },
            // 展示要修改的内容
            async showEditDialog(id){
                console.log(id);
                this.ids.id=id
                this.editDialogVisible=true
                const {data:res}= await this.$http.put('adminRole/detail',this.ids)
                if(res.code!==0) return this.$message.error('查询失败')
                this.editForm=res.data
            },
            editDialogClosed(){
                 this.$refs['editFormRef'].resetFields();
            },
            editUser(){
                // 预验证
                 this.$refs.editFormRef.validate(async valid=>{
                    if(!valid) return
                    // 调用后台数据创建用户
                   const {data:res}=await this.$http.post('adminRole/edit',this.editForm)
                   if(res.code!==0) return this.$message.error(res.message)
                   this.$message.success('编辑成功')
                    //    关闭添加窗口
                    this.editDialogVisible=false
                    this.getinforList()

                })
            },
        },
    }
</script>

<style lang="less" scoped>
</style>