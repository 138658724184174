import { render, staticRenderFns } from "./boardTabulation.vue?vue&type=template&id=d62a31ac&scoped=true"
import script from "./boardTabulation.vue?vue&type=script&lang=js"
export * from "./boardTabulation.vue?vue&type=script&lang=js"
import style0 from "./boardTabulation.vue?vue&type=style&index=0&id=d62a31ac&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d62a31ac",
  null
  
)

export default component.exports