<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>文章管理</el-breadcrumb-item>
      <el-breadcrumb-item>文章列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input
            clearable
            placeholder=" 请输入内容"
            v-model="queryinfo.query"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getinforList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加文章</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="userlist" border stripe>
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="正文">
                <span>{{ props.row.body }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>

        <el-table-column type="index"></el-table-column>
        <el-table-column label="标题" prop="title"> </el-table-column>
        <el-table-column label="内容" prop="done"> </el-table-column>
        <el-table-column label="作者" prop="username"> </el-table-column>
        <el-table-column label="头像">
          <template slot-scope="scope">
            <el-image :src="scope.row.image" fit="fill"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="图片">
          <template slot-scope="scope">
            <el-image :src="scope.row.image5" fit="fill"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row.id)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="deleteEditDialog(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页区域-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pagenum"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="queryinfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!--新增文章-->
    <el-dialog
      title="新增文章"
      :visible.sync="addDialogVisible"
      width="50%"
      @closed="addDialogClosed"
    >
      <el-form
        :model="addForm"
        :rules="addRules"
        ref="addFormRef"
        label-width="100px"
      >
        <el-form-item label="标题" prop="title">
          <el-input v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="话题" prop="size">
          <el-select v-model="addForm.size" placeholder="请选择话题">
            <el-option label="秋" value="400"></el-option>
            <el-option label="早餐马拉松开学季" value="401"></el-option>
            <el-option label="珞的晨之味" value="402"></el-option>
            <el-option label="糯糯糯糯糯糯米" value="403"></el-option>
            <el-option label="九阳（Joyoung）" value="404"></el-option>
            <el-option label="24小时流行作品" value="405"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="作者" prop="editor">
          <el-input v-model="addForm.editor"></el-input>
        </el-form-item>
        <el-form-item label="材料" prop="body">
          <el-input type="textarea" v-model="addForm.body"></el-input>
        </el-form-item>
        <el-form-item label="头像" prop="image">
          <el-upload
            :action="uploadUrl"
            :headers="headerObj"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            class="upload-demo"
            list-type="picture"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="图片" prop="image">
          <el-upload
            :action="uploadUrl"
            :headers="headerObj"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            class="upload-demo"
            list-type="picture"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改文章信息 -->
    <el-dialog
      title="修改文章信息"
      :visible.sync="editDialogVisible"
      width="50%"
      @closed="editDialogClosed"
    >
      <el-form
        :model="editForm"
        :rules="editRules"
        ref="editFormRef"
        label-width="70px"
      >
        <el-form-item label="标题" prop="cons_title">
          <el-input v-model="editForm.cons_title"></el-input>
        </el-form-item>
        <el-form-item label="作者" prop="editor">
          <el-input v-model="editForm.editor"></el-input>
        </el-form-item>
        <!-- <el-form-item label="来源" prop="source">
                    <el-input v-model="editForm.source"></el-input>
                </el-form-item> -->
        <el-form-item label="内容" prop="body">
          <el-input type="textarea" v-model="editForm.body"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUser">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取文章信息列表
      queryinfo: {
        query: "",
        pagenum: 1,
        pagesize: 5,
      },
      userlist: [],
      total: 0,
      //控制创建文章对话框展示与隐藏
      addDialogVisible: false,
      addForm: {
        title: "",
        editor: "",
        size: "",
        body: "",
        // 上传的图片临时路径
        pics: [],
      },
      addRules: {
        title: [
          {
            required: true,
            message: "请输入文章标题",
            trigger: "blur",
          },
        ],
        editor: [
          {
            required: true,
            message: "请输入作者名称",
            trigger: "blur",
          },
        ],
        size: [
          {
            required: true,
            message: "请选择话题",
            trigger: "blur",
          },
        ],
        body: [
          {
            required: true,
            message: "请输入文本内容",
            trigger: "blur",
          },
        ],
      },
      editDialogVisible: false,
      editForm: {},
      editRules: {
        title: [
          {
            required: true,
            message: "请输入文章标题",
            trigger: "blur",
          },
        ],
        editor: [
          {
            required: true,
            message: "请输入作者名称",
            trigger: "blur",
          },
        ],
        size: [
          {
            required: true,
            message: "请选择话题",
            trigger: "blur",
          },
        ],
        source: [
          {
            required: true,
            message: "请输入文章来源",
            trigger: "blur",
          },
        ],
        body: [
          {
            required: true,
            message: "请输入文本内容",
            trigger: "blur",
          },
        ],
      },
      // 上传图片的url
      uploadUrl: "http://127.0.0.1:8887/api/upload",
      // 图片上传组件的headers请求头对象
      headerObj: {
        Authorization: window.sessionStorage.getItem("token"),
      },
      previewPath: "",
      previewVisible: false,
    };
  },
  created() {
    this.getinforList();
  },
  methods: {
    // 获取提现列表
    async getinforList() {
      const { data: res } = await this.$http.post("drawCashLog/index", {
        params: this.queryinfo,
      });

      if (res.meta.status !== 200)
        return this.$message.err("获取拓展信息列表失败!");
      this.userlist = res.data.infor;
      this.total = res.data.total;
      console.log(res.data);
    },
    // 监听handleSizeChange方法
    handleSizeChange(newsize) {
      this.queryinfo.pagesize = newsize;
      this.getinforList();
    },
    // 监听handleCurrentChange方法
    handleCurrentChange(newpage) {
      this.queryinfo.pagenum = newpage;
      this.getinforList();
    },
    
    //删除用户
    async deleteEditDialog(id) {
      // console.log(id);
      const confirmRessult = await this.$confirm(
        "此操作将永久删除, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);

      if (confirmRessult !== "confirm") {
        return this.$message.info("已经取消删除!");
      }
      const { data: res } = await this.$http.delete(`knowledge/${id}`);
      if (res.meta.status !== 200) {
        return this.$message.error("删除失败!");
      }
      console.log(res);
      this.$message.success("删除成功!");
      this.getinforList();
    },
    // 展示要修改的内容
    async showEditDialog(id) {
      console.log(id);
      this.editDialogVisible = true;
      const { data: res } = await this.$http.put("konwledge/" + id);
      if (res.meta.status !== 200) return this.$message.error("查询失败");
      this.editForm = res.data;
      console.log(res.data);
      console.log(this.editForm);
    },
    editDialogClosed() {
      this.$refs["editFormRef"].resetFields();
    },
    editUser() {
      // 预验证
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return;
        // 调用后台数据创建用户
        const { data: res } = await this.$http.post(
          "updateKonws/" + this.editForm.ids,
          this.editForm
        );
        if (res.meta.status !== 200) return this.$message.error(res.meta.msg);
        this.$message.success("编辑成功");
        console.log(this.editForm);
        //    关闭添加窗口
        this.editDialogVisible = false;
        this.getinforList();
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>